import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isFunction } from "lodash";
import Radio from "antd/es/radio";
import Button from "antd/es/button";
import Text from "antd/es/typography/Text";
import Section from "./Section";
import {
  PageFooter,
  StyledPaymentMethodCard,
  StyledSummaryPaymentDetail,
} from "./PaymentMethodSelection.styles";
import SummaryPaymentDetail from "./SummaryPaymentDetail";
import { shallowEqual, useSelector } from "react-redux";
import { getPaymentMethodsByCurrency } from "../redux/paymentSelectors";
import CustomBookingId from "../../../components/CustomBookingId/CustomBookingId";
import { clickEvents, trackEvent } from "../../../services/mixpanel";
import {
  getAgreementConfig,
  getSupplierSettings,
} from "../../../Redux/Selectors/appConfigSelector";
import { Checkbox, Modal } from "antd";
import RichTextRender from "../../../components/RichTextRender/RichTextRender";
import useIsMobile from "../../../hooks/useIsMobile";
import Paragraph from "antd/es/typography/Paragraph";
import { filterPaymentMethods } from "../helpers/helpers";

const PaymentMethodSelection = ({
  selectedPaymentMethodIdentifier,
  onSelectPaymentMethodIdentifier,
  onContinue,
  summary,
  transactionCustomId,
  currentLink,
}) => {
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();
  const supplierSettings = useSelector(getSupplierSettings);
  const { text, enabled: agreementEnabled } = useSelector((state) =>
    getAgreementConfig(state, shallowEqual)
  );
  const [termsVisible, setTermsVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const paymentMethods = useSelector(getPaymentMethodsByCurrency);
  const filterPayments = filterPaymentMethods(paymentMethods, currentLink);
  const handleClick = (e, paymentIdentifier, title) => {
    trackEvent(clickEvents.SELECT_PAYMENT_METHOD, {
      methodIdentifier: paymentIdentifier,
      title,
    });
    isFunction(onSelectPaymentMethodIdentifier) &&
      onSelectPaymentMethodIdentifier(paymentIdentifier);
  };

  const renderDisclaimer = () => {
    if (agreementEnabled) {
      return (
        <Section title={t("common:agreement")}>
          {text && <Paragraph>{text}</Paragraph>}
          <Paragraph>{t("agreementContinue")}</Paragraph>
          <div style={{ marginTop: 25, marginBottom: 20 }}>
            <Checkbox
              size="large"
              onChange={() => setTermsAccepted(!termsAccepted)}
            />{" "}
            <Text>{t("accordingTo")}</Text>
            <Button
              onClick={() => setTermsVisible(true)}
              type="link"
              style={{
                paddingLeft: 5,
                fontWeight: "600",
              }}
            >
              {t("termsAndConditionsLease")}
            </Button>
          </div>
        </Section>
      );
    }
  };

  const checkButtonDisabled = () => {
    if (agreementEnabled && !termsAccepted) return true;
    return !selectedPaymentMethodIdentifier;
  };

  return (
    <>
      <div>
        <CustomBookingId customId={transactionCustomId} />
        {summary && (
          <Section title={t("paymentSummary")}>
            <StyledSummaryPaymentDetail {...summary} />
          </Section>
        )}
        <Section title={t("selectPaymentMethod")}>
          {filterPayments.map(
            ({ title, description, paymentIdentifier, afterTitleContent }) => {
              const selected =
                selectedPaymentMethodIdentifier === paymentIdentifier;
              return (
                <StyledPaymentMethodCard
                  key={paymentIdentifier}
                  title={title}
                  description={description}
                  afterTitleContent={afterTitleContent}
                  extraContent={
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 20px",
                      }}
                    >
                      <Radio checked={selected} />
                    </div>
                  }
                  onClick={(e) => handleClick(e, paymentIdentifier, title)}
                  selected={selected}
                />
              );
            }
          )}
        </Section>
      </div>
      {renderDisclaimer()}
      <PageFooter>
        <Button
          onClick={onContinue}
          type="primary"
          shape="round"
          size="large"
          disabled={checkButtonDisabled()}
        >
          {t("common:continue")}
        </Button>
      </PageFooter>
      <Modal
        open={termsVisible}
        width={isMobile ? "100%" : 800}
        onCancel={() => setTermsVisible(false)}
        title={t("common:agreement")}
        footer={null}
      >
        <RichTextRender content={supplierSettings?.contract?.body} />
      </Modal>
    </>
  );
};

PaymentMethodSelection.defaultPropTypes = {
  selectedPaymentMethodIdentifier: null,
  guestCurrency: null,
};

PaymentMethodSelection.propTypes = {
  selectedPaymentMethodIdentifier: PropTypes.string,
  onSelectPaymentMethodIdentifier: PropTypes.func,
  onContinue: PropTypes.func,
  transactionCurrency: PropTypes.string.isRequired,
  guestCurrency: PropTypes.string,
  summary: PropTypes.shape(SummaryPaymentDetail.propTypes),
};

export default PaymentMethodSelection;
