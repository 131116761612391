import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpaceDetail,
  getSpaceDetailIsFetching,
} from "../../redux/selectors/spacesSelectors";
import { getSpaceDetailRequest } from "../../redux/actions/spacesActions";
import useVariants from "../../../../hooks/useVariants";

const SpaceDetailContainer = ({ children, spaceId }) => {
  const dispatch = useDispatch();
  const space = useSelector((state) => getSpaceDetail(state, spaceId));
  const isFetching = useSelector(getSpaceDetailIsFetching);
  const variants = useVariants(spaceId);

  useEffect(() => {
    dispatch(getSpaceDetailRequest(spaceId));
  }, []);

  return children({
    space,
    variants,
    isFetching: isFetching,
  });
};

export default SpaceDetailContainer;
