import { getAppConfigState } from "../../../../Redux/Selectors/appConfigSelector";
import {
  getProductById,
  getProducts,
} from "../../../../Redux/Selectors/productSelectors";
import { listRestrictedDates } from "../../../../helpers/supplierSettings";

export const getAllSpaces = (state) => {
  const { results } = state.pages.spaces.list;
  const spaces = getProducts(state, results);
  return spaces.reverse();
};

export const getAllSpacesIsFetching = (state) => {
  const { isFetching } = state.pages.spaces.list;
  return isFetching;
};

export const getSpaceDetail = (state) => {
  const { data } = state.pages.spaces.detail;
  const spaces = getProductById(state, data);
  return spaces;
};

export const getSpaceDetailIsFetching = (state) => {
  const { isFetching } = state.pages.spaces.detail;
  return isFetching;
};

export const getDisabledDates = (state) => {
  const { restrictedDates } =
    getAppConfigState(state)?.data?.supplier?.settings;
  const disabledDates = listRestrictedDates(restrictedDates);
  const { data } = state.pages.spaces.availability;
  return data?.concat(disabledDates);
};
