import { Divider, Form } from "antd";
import React from "react";
import CountrySelectForm from "../../../components/FormInputs/CountrySelectForm";
import PersonalIdInput from "../../../components/FormInputs/PersonalIdInput";
import { CUSTOMER_TYPE } from "../../../components/FormInputs/constants";
import { useTranslation } from "react-i18next";
import Input from "antd/es/input/Input";
import PhoneInput from "../../../components/FormInputs/PhoneInput";
import useIsMobile from "../../../hooks/useIsMobile";
import { FormContainer } from "../CheckinScreen.styles";
import { Button } from "../../../components/Button/Button.styles";
import Paragraph from "antd/es/typography/Paragraph";
import { useSupplierConfig } from "../../../hooks/useSupplierConfig";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { getMainMemberInfo } from "../../ShopPage/redux/selectors/MainMemberSelectors";
import ReferralForm from "./ReferralForm";
import useReferrals from "../../../hooks/useReferrals";
import { isEmpty } from "lodash";
import { sanitizeReferrals } from "../helpers/helpers";
import { isCircuitoChico } from "../../../helpers/rentals";
import { ANY_NUMBERS } from "../helpers/constants";

const MainMemberForm = ({ onSubmitMainMember }) => {
  const { t } = useTranslation("shop");
  const { createCustomer } = useSupplierConfig();
  const mainMember = useSelector(getMainMemberInfo);
  const supplierData = useSelector(getSupplierData);
  const { referrals, isReferralsFetching } = useReferrals();
  const sanitizedReferrals = sanitizeReferrals(referrals);

  const isMobile = useIsMobile();
  return (
    <FormContainer>
      <Form
        onFinish={onSubmitMainMember}
        layout="vertical"
        initialValues={{
          phonePrefix:
            mainMember?.phonePrefix || createCustomer.phonePrefixByRegion,
          type: mainMember?.type || createCustomer.personalIdType,
          nationality: mainMember?.nationality || createCustomer.nationality,
          createdBy: supplierData._id,
          customerType: CUSTOMER_TYPE.PERSONAL,
          name: mainMember?.name,
          surname: mainMember?.surname,
          email: mainMember?.email,
          dateOfBirth: mainMember?.dateOfBirth,
          number: mainMember?.number,
          referral: mainMember?.referral,
          phoneNumber: mainMember?.phoneNumber,
          housing: mainMember?.housingInfo?.hotel,
        }}
      >
        <Form.Item
          style={{ marginBottom: 10 }}
          name="name"
          label={t("form.name")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.name") }),
            },
            {
              validator: (_, value) => {
                if (value && ANY_NUMBERS.test(value)) {
                  return Promise.reject(t("shop:form.onlyLetters"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={t("form.placeholderName")} />
        </Form.Item>

        <Form.Item
          name="surname"
          label={t("form.surname")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.surname") }),
            },
            {
              validator: (_, value) => {
                if (value && ANY_NUMBERS.test(value)) {
                  return Promise.reject(t("shop:form.onlyLetters"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={t("form.placeholderSurname")} />
        </Form.Item>
        <CountrySelectForm name="nationality" required size="large" />
        <PersonalIdInput
          typeWidth={isMobile ? 130 : 180}
          customerType={CUSTOMER_TYPE.PERSONAL}
          required
          size="regular"
          placeholder={t("form.placeholderPersonalId")}
        />
        <Form.Item
          name="email"
          label={t("form.email")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.email") }),
            },
            {
              type: "email",
              message: t("form.error", { field: t("form.email") }),
            },
          ]}
        >
          <Input size="regular" placeholder={t("form.placeholderEmail")} />
        </Form.Item>
        <PhoneInput
          prefixWidth={isMobile ? 130 : 180}
          required
          placeholder={t("form.placeholderPhoneNumber")}
        />
        <Form.Item
          name="housing"
          label={t("form.customerAddressAndAccommodation")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.housing") }),
            },
            {
              type: "housingInfo",
              message: t("form.housing", { field: t("form.housing") }),
            },
          ]}
        >
          <Input size="regular" placeholder={t("form.placeholderHousing")} />
        </Form.Item>
        {!isEmpty(sanitizedReferrals) && isCircuitoChico(supplierData?._id) && (
          <>
            <Divider />
            <Paragraph style={{ fontSize: 14 }}>
              {t("referralSectionTitle")}
            </Paragraph>
            <ReferralForm
              referrals={sanitizedReferrals}
              isReferralsFetching={isReferralsFetching}
            />
            <Divider />
          </>
        )}
        <Form.Item noStyle>
          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            style={{ marginTop: 20 }}
          >
            {t("common:continue")}
          </Button>
        </Form.Item>
      </Form>
    </FormContainer>
  );
};

export default MainMemberForm;
