import React from "react";
import PropTypes from "prop-types";
import Text from "antd/es/typography/Text";
import {
  CardHeader,
  Wrapper,
  CardTitle,
  AfterTitle,
  CardBody,
  Content,
  ExtraContent,
} from "./PaymentMethodCard.styles";
import { useTranslation } from "react-i18next";

const PaymentMethodCard = ({
  title,
  description,
  afterTitleContent,
  extraContent,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...restProps}>
      <Content>
        <CardHeader>
          <CardTitle level={5}>{t(title)}</CardTitle>
          {!!afterTitleContent && <AfterTitle>{afterTitleContent}</AfterTitle>}
        </CardHeader>
        {!!description && (
          <CardBody>
            <Text style={{fontSize: 14}}>{t(description)}</Text>
          </CardBody>
        )}
      </Content>
      {!!extraContent && <ExtraContent>{extraContent}</ExtraContent>}
    </Wrapper>
  );
};

PaymentMethodCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  afterTitleContent: PropTypes.node,
  extraContent: PropTypes.node,
};

export default PaymentMethodCard;
