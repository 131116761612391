import React from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PERIODS } from "../../helpers/tools";

const Container = styled.div``;
const Date = styled.div``;
const StyledText = styled(Text)`
  font-size: 14px;
`;

const ExperienceDates = ({
  from,
  to,
  format = "DD/MM/YY - HH:mm",
  period,
  daysQuantity,
}) => {
  const { t } = useTranslation(["shop", "experiences"]);

  if (period === PERIODS.hour) {
    return (
      <Container>
        <Date>
          <StyledText>{t("startDate")}:</StyledText>{" "}
          <StyledText strong>{moment(from).format(format)}</StyledText>
        </Date>
        <Date>
          <StyledText>{t("dropOffDate")}:</StyledText>{" "}
          <StyledText strong>{moment(to).format(format)}</StyledText>
        </Date>
      </Container>
    );
  }

  return (
    <Container>
      <Date>
        <StyledText>{t("startDate")}:</StyledText>{" "}
        <StyledText strong>{moment(from).format(format)}</StyledText>
      </Date>
      <StyledText>
        <StyledText>{t("experiences:duration")}:</StyledText>{" "}
        <StyledText strong>{t(period, { count: daysQuantity })}</StyledText>{" "}
      </StyledText>
    </Container>
  );
};

export default ExperienceDates;
