import { useDispatch, useSelector } from "react-redux";
import {
  getItems,
  getPricingData,
  getPricingIsFetching,
  getSummary,
} from "../../../ShopPage/redux/selectors/ShopSelectors";
import {
  getPricingRequest,
  setDates,
  summaryAddTicket,
  summarySubtractTicket,
} from "../../../ShopPage/redux/actions/shopActions";
import {
  buildItems,
  calculateSupplierDaysLength,
  createUTCDate,
  normalizeProducts,
  toISOFormatString,
} from "../../../../helpers/tools";
import { listTypes } from "../../../ShopPage/utils";
import { useEffect, useState } from "react";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import { isEmpty } from "lodash";
import { modifyValueSearch } from "../../../../Redux/Actions/searchActions";
import { checkVariantsStockRequest } from "../../../ShopPage/redux/actions/AvailabiltyActions";
import { getLockedDatesRequest } from "../../redux/actions/spacesActions";
import { getDisabledDates } from "../../redux/selectors/spacesSelectors";
import { useTranslation } from "react-i18next";
import { getProductById } from "../../../../Redux/Selectors/productSelectors";
import { parseApiDate } from "../../../../helpers/dates";

const AddParticipantContainer = ({ children, variants, spaceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["shop"]);
  const pricingData = useSelector(getPricingData);
  const spaceData = useSelector((state) => getProductById(state, spaceId));
  const [validationMessage, setValidationMessage] = useState([]);
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const quoteSummary = useSelector(getSummary);
  const selectedProducts = useSelector((state) =>
    getItems(state, normalizeProducts(quoteSummary?.products))
  );
  const disableDates = useSelector(getDisabledDates);
  const isPricingFetching = useSelector(getPricingIsFetching);

  const daysLength = calculateSupplierDaysLength(
    createUTCDate(dates.from),
    createUTCDate(dates.to)
  );

  const pricingDaysQuantity = () => {
    let quantity = daysLength;
    return quantity < 0 ? 0 : quantity;
  };

  useState(() => {
    if (createUTCDate(dates.from).isSame(createUTCDate())) {
      dispatch(
        modifyValueSearch("dates", {
          from: null,
          to: null,
        })
      );
      dispatch(
        setDates({
          from: null,
          to: null,
        })
      );
    }
  }, []);

  const pricingRequest = () => {
    dispatch(
      getPricingRequest(
        toISOFormatString(dates.from),
        toISOFormatString(
          createUTCDate(dates.from).add(pricingDaysQuantity() - 1, "days")
        ),
        "day",
        buildItems(quoteSummary, selectedProducts),
        {},
        "ARS",
        true,
        listTypes.PRODUCT
      )
    );
  };

  const handleMonthChange = (date) => {
    const monthDate = parseApiDate(date);
    const startDate = monthDate.clone().subtract(1, "month").startOf("month");
    const endDate = monthDate.clone().add(1, "month").endOf("month");
    dispatch(
      getLockedDatesRequest(
        variants[0]?.id,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        "day"
      )
    );
  };

  useEffect(() => {
    if (variants.length > 0) {
      handleMonthChange(new Date());
    }
  }, [JSON.stringify(variants)]);

  useEffect(() => {
    if (
      !isEmpty(dates) &&
      !isEmpty(selectedProducts) &&
      dates.from &&
      dates.to
    ) {
      pricingRequest();
    }
  }, [JSON.stringify(dates), JSON.stringify(selectedProducts)]);

  useEffect(() => {
    const warnings = [];
    if (!dates.from || !dates.to) {
      warnings.push(t("warning.pickDate"));
    }
    if (isEmpty(quoteSummary.products)) {
      warnings.push(t("warning.pickQuantity"));
    }
    if (quoteSummary.products[spaceId] < spaceData.minimumBookingQuantity) {
      warnings.push(
        t("warning.minimumBookingQuantity", {
          count: spaceData.minimumBookingQuantity,
        })
      );
    }
    if (daysLength < spaceData.minimumPeriodQuantity) {
      warnings.push(
        t("warning.minimumPeriodQuantity", {
          count: spaceData.minimumPeriodQuantity,
          period: "días",
        })
      );
    }
    setValidationMessage(warnings);
  }, [JSON.stringify(dates), JSON.stringify(quoteSummary)]);

  const handleOnAddParticipant = (rate) => {
    dispatch(summaryAddTicket(rate));
  };

  const handleOnRemoveParticipant = (rate) => {
    dispatch(summarySubtractTicket(rate));
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    console.log("variants", variants);
    const variantIds = variants.map((variant) => variant.id);
    const from = start ? createUTCDate(start).set("hour", 10).toDate() : null;
    const to = end ? createUTCDate(end).set("hour", 23).toDate() : null;
    dispatch(
      modifyValueSearch("dates", {
        from,
        to,
      })
    );
    if (start && end && variantIds.length > 0) {
      dispatch(checkVariantsStockRequest(variantIds, start, end, "day"));
    }
    dispatch(
      setDates({
        from,
        to,
      })
    );
  };

  return children({
    handleOnAddParticipant,
    handleOnRemoveParticipant,
    pricingData,
    isPricingFetching,
    onDateChange,
    handleMonthChange,
    startDate: dates.from,
    endDate: dates.to,
    quoteSummary,
    disableDates: disableDates?.map((date) => parseApiDate(date).toDate()),
    validationMessage,
    daysLength,
    pricingDaysQuantity: pricingDaysQuantity(),
    maxItems: variants[0]?.stock,
    minItems: spaceData.minimumBookingQuantity,
  });
};

export default AddParticipantContainer;
