import { CheckOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import Text from "antd/es/typography/Text";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  getAssigmentByFieldKey,
  getPassengersByKey,
} from "../../redux/selectors/ShopSelectors";
import AssignVariantAndPeopleDataForm from "../AssignVariantForm/AssignVariantForm";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
  width: 100%;
`;

const AssignProductsForm = ({
  formController,
  initialFormData,
  handleOnSubmitAssign,
}) => {
  const [showModal, setShoModal] = useState({
    visible: false,
    data: null,
    isBundle: false,
  });
  const handleAssignProduct = (product, fieldKey, assigned) => {
    let productIds;
    let bundleId;
    if (product && !isEmpty(product.products)) {
      productIds = product.products.map(({ productId }) => productId);
      bundleId = product._id;
    }
    const id = !isEmpty(productIds) ? productIds : product?._id;
    const isBundle = !isEmpty(productIds);
    setShoModal({
      visible: true,
      data: {
        assigned,
        fieldKey,
        id,
        bundleId,
      },
      isBundle,
    });
  };

  const handleCloseModal = () => {
    setShoModal({
      visible: false,
      data: null,
      isBundle: false,
    });
  };
  return (
    <>
      <Form
        style={{ marginTop: 20 }}
        form={formController}
        initialValues={{
          products: initialFormData.productsDetail.map((data) => data),
        }}
      >
        <Form.List name="products">
          {(fields) => {
            return (
              <>
                {fields.map((field) => (
                  <Form.Item
                    {...field}
                    required={true}
                    key={field.key}
                    noStyle
                    style={{ width: "100%" }}
                  >
                    <ItemWrapper
                      handleAssignProduct={handleAssignProduct}
                      assigments={initialFormData.assigments}
                      fieldKey={field.key}
                    />
                  </Form.Item>
                ))}
              </>
            );
          }}
        </Form.List>
      </Form>

      <AssignVariantAndPeopleDataForm
        visible={showModal.visible}
        data={showModal.data}
        isBundle={showModal.isBundle}
        people={Object.values(initialFormData.passengers)}
        onCancel={handleCloseModal}
        handleOnSubmit={handleOnSubmitAssign}
      />
    </>
  );
};

const ItemWrapper = ({ value, handleAssignProduct, fieldKey }) => {
  const { t } = useTranslation();
  const assigned = useSelector((state) =>
    getAssigmentByFieldKey(state, fieldKey)
  );
  const passenger = useSelector((state) =>
    getPassengersByKey(state, assigned?.passenger)
  );
  const generateStyle = () => {
    if (assigned) {
      return {
        backgroundColor: "#4BB543",
        borderColor: "#4BB543",
        color: "white",
      };
    }
    return {};
  };

  return (
    <Row>
      <Text>{value.title}</Text>
      <Button
        style={generateStyle()}
        type="primary"
        onClick={() => handleAssignProduct(value, fieldKey, assigned)}
        icon={assigned ? <CheckOutlined /> : null}
        data-testid="quote-assign-passenger-button"
      >
        {assigned ? passenger?.name : t("shop:assign.assign")}
      </Button>
    </Row>
  );
};

export default AssignProductsForm;
