import React, { useEffect } from "react";
import Form from "antd/es/form";
import Select from "antd/es/select";
import { useTranslation } from "react-i18next";
import useVariants from "../../../hooks/useVariants";
import { useDispatch, useSelector } from "react-redux";
import { getItemById, getSummary } from "../redux/selectors/ShopSelectors";
import { Col, Row, Typography } from "antd";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { getStock } from "../redux/selectors/AvailabilitySelectors";
import { isEmpty } from "lodash";
import { toISOFormatString } from "../../../helpers/tools";
import { checkVariantsStockRequest } from "../redux/actions/AvailabiltyActions";
import useCalculateDates from "../../../hooks/useCalculateDays";
const { Text } = Typography;

const PickVariant = ({ productId, multiple = false }) => {
  const { t } = useTranslation();
  const variants = useVariants(productId);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const quoteSummary = useSelector(getSummary);
  const { from, to } = useCalculateDates({
    fromDate: params.from,
    toDate: params.to,
    fromTime: params.pickupTime,
    toTime: params.dropoffTime,
    period: quoteSummary.period,
  });

  const { stock, fetching } = useSelector(getStock);
  const product = useSelector((state) => getItemById(state, productId));
  const fieldName = `variant-${productId}`;

  useEffect(() => {
    if (!isEmpty(variants)) {
      const variantIds = variants.map((variant) => variant.id);
      dispatch(
        checkVariantsStockRequest(
          variantIds,
          toISOFormatString(from),
          toISOFormatString(to),
          product?.rentalPeriodType
        )
      );
    }
  }, [JSON.stringify(variants)]);

  return (
    <>
      {multiple && <Text>{product?.title}</Text>}
      <Form.Item
        name={fieldName}
        rules={[
          {
            required: true,
            message: t("shop:assign.pickVariant"),
          },
        ]}
      >
        
        <Select
          data-testid="quote-pick-variant-select"
          loading={fetching}
          disabled={fetching}
        >
          {variants.length === 0 && (
            <Select.Option value={null}>{t("loading")}</Select.Option>
          )}

          {variants?.map((variant, index) => {
            const hasStock = Boolean(stock[variant?.id]);
            return (
              <Select.Option
                key={index}
                value={variant?.id}
                disabled={!hasStock}
              >
                <Row justify={"space-between"}>
                  <Col>
                    {variant.value} {t(`shop:${variant.unit}`)}
                  </Col>
                  {!hasStock && (
                    <Col>
                      <Text type="danger">{t(`shop:outOfStock`)}</Text>
                    </Col>
                  )}
                </Row>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default PickVariant;
