import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DateInput from "../DateInput";
import Text from "antd/es/typography/Text";
import TimePicker from "../../../../components/TimePicker/TimePicker";
import TimeQuantityPicker from "../../../../components/TimeQuantityPicker/TimeQuantityPicker";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import {
  PickUpAndDropOffWrapper,
  StyledColumn,
  StyledRow,
  StyledText,
} from "./PeriodCalendars.styles";
import { ShopIcon } from "../../../../../CheckInPage/CheckinScreen.styles";
import SelectBranch from "../../../../../CheckInPage/components/SelectBranch/SelectBranch";
import { createUTCDate, parseHour } from "../../../../../../helpers/dates";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { modifyValueSearch } from "../../../../../../Redux/Actions/searchActions";
import { isModoMono } from "../../../../../../helpers/rentals";
import { getSupplierData } from "../../../../../../Redux/Selectors/appConfigSelector";

const HourCalendarInput = ({
  from,
  align,
  handleOnTimeChange,
  fromHours,
  onClick,
  disabledPickUpBranch,
  disabledDropOffBranch,
  pickUpBranch,
  dropOffBranch,
  supplierGroupsOptions,
  handleSetPickUpAndDropOffBranch,
  pickUpTime,
  quantityTime,
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { t } = useTranslation("shop");
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const handleTimeQuantityChange = (value) => {
    handleOnTimeChange("quantityTime", parseInt(value));
  };

  const handleFromTimeChange = (key, value) => {
    handleOnTimeChange("pickupTime", value);
  };

  const modoMonoActivo = isModoMono(supplierData?._id);

  useEffect(() => {
    if (quantityTime && pickUpTime) {
      const toDropOff = createUTCDate(from)
        .hour(pickUpTime.split(":")[0])
        .minute(pickUpTime.split(":")[1])
        .add(quantityTime, "hours");
      modoMonoActivo && quantityTime === 6
        ? handleOnTimeChange("dropoffTime", "19:00")
        : handleOnTimeChange("dropoffTime", `${toDropOff.format("HH")}:00`);

      dispatch(
        modifyValueSearch("dates", {
          from,
          to: toDropOff,
        })
      );
    }
  }, [quantityTime, pickUpTime]);

  return (
    <PickUpAndDropOffWrapper $isMobile={isMobile}>
      <Text>{t("serviceDetail")}</Text>
      {isModoMono(supplierData._id) && (
        <StyledRow columns={isMobile ? 1 : 2}>
          <StyledColumn>
            <StyledText>{t("pickUp")}</StyledText>
            <SelectBranch
              options={supplierGroupsOptions}
              value={pickUpBranch}
              disabled={disabledPickUpBranch}
              handleSelectBranch={(value, branchData) =>
                handleSetPickUpAndDropOffBranch(
                  value,
                  "pickUpBranch",
                  branchData
                )
              }
              icon={<ShopIcon />}
            />
          </StyledColumn>

          <StyledColumn>
            <Text style={{ fontSize: 16 }}>{t("dropOff")}</Text>

            <SelectBranch
              style={{ marginBottom: isMobile && "15px" }}
              options={supplierGroupsOptions}
              disabled={disabledDropOffBranch}
              value={dropOffBranch}
              handleSelectBranch={(value, branchData) =>
                handleSetPickUpAndDropOffBranch(
                  value,
                  "dropOffBranch",
                  branchData
                )
              }
              icon={<ShopIcon />}
            />
          </StyledColumn>
        </StyledRow>
      )}

      <StyledRow columns={isMobile ? 1 : 3}>
        <DateInput
          label={t("startDate")}
          value={from}
          align={align}
          onClick={onClick}
        />

        <TimeQuantityPicker
          onChange={handleTimeQuantityChange}
          value={quantityTime}
          values={[
            {
              label: "1 hora",
              value: "1",
              isMonoOnly: true,
            },
            {
              label: "2 horas",
              value: "2",
              isMonoOnly: true,
            },
            {
              label: "4 horas",
              value: "4",
              isMonoOnly: true,
            },
            {
              label: "Todo el día",
              value: "8",
              isMonoOnly: !modoMonoActivo,
            },
            {
              label: "Full Day (6 a 12hs)",
              value: "6",
              isMonoOnly: modoMonoActivo,
            },
            {
              label: "24 horas",
              value: "24",
              isMonoOnly: modoMonoActivo,
            },
            {
              label: "48 horas",
              value: "48",
              isMonoOnly: modoMonoActivo,
            },
            {
              label: "72 horas",
              value: "72",
              isMonoOnly: modoMonoActivo,
            },
          ]}
          label={t("quantityByPeriods.numberOfHoursToReserve")}
        />

        <TimePicker
          label={t("startDate")}
          onChange={handleFromTimeChange}
          keyVal="pickupTime"
          fromTime={fromHours?.map((hour) => hour.from.split(":")[0]).flat()}
          toTime={fromHours?.map((hour) => hour.to.split(":")[0]).flat()}
          disabled={quantityTime === null}
          dateSelected={from}
          amountSelected={quantityTime}
          timeSelected={parseHour(pickUpTime)}
        />
      </StyledRow>
    </PickUpAndDropOffWrapper>
  );
};

export default HourCalendarInput;
