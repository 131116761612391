import { PAYMENT_METHOD_LABELS, URL_FROM_DASHBOARD } from "../PaymentMethods";

export const filterPaymentMethods = (paymentMethods = [], source = "") => {
  const isDashboardSource = source.includes(URL_FROM_DASHBOARD.DASHBOARD);
  const allowedPaymentMethods = [
    PAYMENT_METHOD_LABELS.mercadopago,
    PAYMENT_METHOD_LABELS.stripe,
    PAYMENT_METHOD_LABELS.webpay,
  ];
  if (!isDashboardSource) {
    return paymentMethods;
  }
  const filteredPaymentMethods = paymentMethods.filter((method) => {
    if (allowedPaymentMethods.includes(method.paymentMethod)) {
      return true;
    }
    return false;
  });

  return filteredPaymentMethods;
};
