import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import OverlaySpiner from "../../../../components/OverlaySpinner/OverlaySpinner";
import { transactionPaymentReset } from "../../redux/paymentActions";
import { getStatusData } from "../../utils";
import { PAYMENT_METHOD_LABELS } from "../../PaymentMethods";
import PaymentResult from "../PaymentResult";
import StripePaymentScreen from "../../screens/StripePaymentScreen";
import WebpayPaymentScreen from "../../screens/WebpayPaymentScreen";
import AlternativePaymentMethodScreen from "../../screens/AlternativePaymentMethodScreen";
import { useNavigate } from "react-router-dom";
import WireTransferPaymentMethodScreen from "../../screens/WireTransferPaymentScreen";
import CashPaymentMethodScreen from "../../screens/CashPaymentScreen";
import WesternUnionPaymentScreen from "../../screens/WesternUnionPaymentScreen";
import MercadoPagoCheckoutPaymentScreen from "../../screens/MercadoPagoCheckoutPaymentScreen";
import ManualPaymentMethodScreen from "../../screens/ManualPaymentMethodScreen";

const Wrapper = styled.div`
  position: relative;
`;

const PaymentMethodForm = ({
  transactionCustomId,
  transactionId,
  amount,
  currency,
  paymentMethodIdentifier,
  paymentMethodData,
  summary,
  transactionStatus,
}) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation("result");
  const dispatch = useDispatch();

  const handleReady = () => {
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      dispatch(transactionPaymentReset());
    };
  }, []);

  /**
   * @param {Object} params
   * @param {"success"|"error"} params.type
   * @param {any} params.content
   */
  const handleResult = (params) => {
    const { type, content } = params;
    const { isSuccess, titleLabel, buttonLabel } = getStatusData(type);
    const title = t(titleLabel);
    const onClick = () =>
      isSuccess ? navigate(`/`) : navigate(`/transaction/${transactionId}/pay`);
    const buttonText = t(buttonLabel);
    setResult({
      status: type,
      title,
      children: content,
      onClick,
      buttonText,
    });
  };

  const renderPaymentScreen = () => {
    const paymentScreenProps = {
      transactionCustomId,
      summary,
      transactionId,
      amount,
      currency,
      paymentMethodIdentifier,
      onReady: handleReady,
      onFinish: handleResult,
      transactionStatus,
    };

    if (
      paymentMethodData?.paymentMethod === PAYMENT_METHOD_LABELS.mercadopago
    ) {
      return <MercadoPagoCheckoutPaymentScreen {...paymentScreenProps} />;
    } else if (
      paymentMethodData?.paymentMethod === PAYMENT_METHOD_LABELS.stripe
    ) {
      return <StripePaymentScreen {...paymentScreenProps} />;
    } else if (
      paymentMethodData?.paymentMethod === PAYMENT_METHOD_LABELS.westernUnion
    ) {
      return <WesternUnionPaymentScreen {...paymentScreenProps} />;
    } else if (
      paymentMethodData?.paymentMethod === PAYMENT_METHOD_LABELS.webpay
    ) {
      return <WebpayPaymentScreen {...paymentScreenProps} />;
    } else if (
      paymentMethodData?.paymentMethod === PAYMENT_METHOD_LABELS.wireTransfer
    ) {
      return <WireTransferPaymentMethodScreen {...paymentScreenProps} />;
    } else if (
      paymentMethodData?.paymentMethod === PAYMENT_METHOD_LABELS.cash
    ) {
      return <CashPaymentMethodScreen {...paymentScreenProps} />;
    } else if (
      paymentMethodData?.paymentMethod === PAYMENT_METHOD_LABELS.macro
    ) {
      return <ManualPaymentMethodScreen {...paymentScreenProps} />;
    }
    return <AlternativePaymentMethodScreen {...paymentScreenProps} />;
  };

  if (!paymentMethodIdentifier) {
    navigate("/");
  }

  if (result && !isEmpty(result)) {
    return <PaymentResult {...result} />;
  }

  return (
    <Wrapper>
      {loading && <OverlaySpiner isLoading={loading} />}
      {renderPaymentScreen()}
    </Wrapper>
  );
};

PaymentMethodForm.propTypes = {
  transactionId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  paymentMethodIdentifier: PropTypes.string,
  paymentMethodData: PropTypes.object,
};

export default PaymentMethodForm;
