export const MODIFY_VALUE_SEARCH = "MODIFY_VALUE_SEARCH";
export const SET_SEARCH_PRESETS = "SET_SEARCH_PRESETS";
export const SEARCH_BY_NAME = "SEARCH_BY_NAME";
export const RESET_PICKUP_AND_DROPOF_TIME = "RESET_PICKUP_AND_DROPOF_TIME";

export const modifyValueSearch = (key, value) => {
  return {
    type: MODIFY_VALUE_SEARCH,
    payload: {
      key,
      value,
    },
  };
};

export const resetPickUpAndDropOfTime = (time) => {
  return {
    type: RESET_PICKUP_AND_DROPOF_TIME,
    payload: {
      time,
    },
  };
};

export const setSearchPreset = ({ city, activities, dates, passengers }) => {
  return {
    type: SET_SEARCH_PRESETS,
    payload: {
      city,
      activities,
      dates,
      passengers,
    },
  };
};

export const setSearchByName = (searchByName) => {
  return {
    type: SEARCH_BY_NAME,
    payload: { searchByName },
  };
};
