import {
  PERFORM_TRANSACTION_FAILURE,
  PERFORM_TRANSACTION_REQUEST,
  PERFORM_TRANSACTION_SUCCESS,
} from "../../../TransactionPage/TransactionActions";

export const INITIAL_STATE = {
  fetching: false,
  error: false,
  data: null,
  errorMessage: null,
};

const transactionStatusReducer = (state = INITIAL_STATE, action) => {
  const { payload: { result, errorMessage } = {} } = action;
  switch (action.type) {
    case PERFORM_TRANSACTION_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: null,
      };
    case PERFORM_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: result,
      };
    case PERFORM_TRANSACTION_FAILURE:
      return {
        ...state,
        fetching: false,
        data: null,
        error: true,
        errorMessage,
      };
    default:
      return state;
  }
};

export default transactionStatusReducer;
