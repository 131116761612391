import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  calculateItemsLength,
  normalizeProducts,
} from "../../../../helpers/tools";
import { getSearchValues } from "../../../../Redux/Selectors/searchSelectors";
import { getSupplierProfileData } from "../../../../Redux/Selectors/supplierSelectors";
import { assignVariantToPassenger } from "../../redux/actions/shopActions";
import {
  getAssigmentsData,
  getAssignmentsLength,
  getPassengersData,
  getSummary,
  getItems,
} from "../../redux/selectors/ShopSelectors";
import { ITEM_TYPE } from "../../constants";

const AssignmentsContainer = ({ children }) => {
  const dispatch = useDispatch();
  const [assignFormController] = Form.useForm();
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const searchValues = useSelector(getSearchValues);
  const navigate = useNavigate();
  const quoteSummary = useSelector(getSummary);
  const passengersData = useSelector(getPassengersData);
  const assigments = useSelector(getAssigmentsData);
  const assignmentsLength = useSelector(getAssignmentsLength);
  const productsDetail = useSelector((state) =>
    getItems(state, normalizeProducts(quoteSummary.products))
  );
  const itemsLength = calculateItemsLength(quoteSummary?.products);
  const supplierData = useSelector(getSupplierProfileData);
  const handleOnSubmitAssign = ({
    passenger,
    productId,
    bundleId = 0,
    fieldKey,
    variantId,
    type,
    ...values
  }) => {
    const variantKeys = Object.keys(values).filter((key) =>
      key.startsWith("variant-")
    );
    if (type === ITEM_TYPE.BUNDLE) {
      variantId = variantKeys.map((key) => {
        const variantProductId = key.split("-")[1];
        return {
          reference: values[key],
          type: ITEM_TYPE.VARIANT,
          bundleId,
          productId: variantProductId,
        };
      });
    } else {
      variantId = values[variantKeys[0]];
    }
    dispatch(
      assignVariantToPassenger({
        passenger,
        variantId,
        productId,
        bundleId,
        index: fieldKey,
        type,
      })
    );
  };

  useEffect(() => {
    if (assignmentsLength === itemsLength) {
      setButtonEnabled(true);
    }
  }, [assigments]);

  const handleOnAssignProductsContinue = () => {
    navigate(`/shop/checkout${window.location.search}`);
  };
  const quoteData = {
    passengers: passengersData,
    quoteSummary,
    productsDetail,
    assigments,
  };
  return children({
    quoteSummary,
    quoteData,
    handleOnSubmitAssign,
    assignFormController,
    handleOnAssignProductsContinue,
    itemsLength,
    isButtonEnabled,
    supplierData,
    selectedProducts: productsDetail,
    searchValues,
  });
};

export default AssignmentsContainer;
