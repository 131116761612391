import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Card, Descriptions, Divider } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPaymentMethodsFromSupplierRequest,
  transactionPaymentCreateRequest,
} from "../redux/paymentActions";
import {
  getPaymentCreationState,
  getPaymentMethodsData,
} from "../redux/paymentSelectors";
import { getPaymentById } from "../../../Redux/Selectors/paymentSelector";
import Title from "antd/es/typography/Title";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import CustomBookingId from "../../../components/CustomBookingId/CustomBookingId";
import { StyledSummaryPaymentDetail } from "../components/PaymentMethodSelection.styles";
import Section from "../components/Section";
import { WarningOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import baseTheme from "../../../config/baseTheme";
import { PAYMENT_METHOD_LABELS } from "../PaymentMethods";

const WireTransferPaymentMethodScreen = ({
  transactionId,
  transactionCustomId,
  amount,
  currency,
  onReady,
  summary,
}) => {
  const { t } = useTranslation(["shop", "common"]);
  const dispatch = useDispatch();
  const {
    paymentId,
    loading: paymentCreationLoading,
    error: paymentCreationError,
    success: paymentCreationSuccess,
  } = useSelector(getPaymentCreationState, shallowEqual);
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const payment = useSelector((state) => getPaymentById(state, paymentId));
  const paymentMethodData = useSelector((state) =>
    getPaymentMethodsData(state, PAYMENT_METHOD_LABELS.wireTransfer)
  );

  useEffect(() => {
    dispatch(
      transactionPaymentCreateRequest({
        provider: PAYMENT_METHOD_LABELS.wireTransfer,
        amount,
        currency,
        transactionId,
      })
    );
    dispatch(getPaymentMethodsFromSupplierRequest(supplierData._id));
  }, []);

  useEffect(() => {
    if (
      !payment ||
      paymentCreationLoading ||
      (!paymentCreationSuccess && !paymentCreationError)
    ) {
      return;
    }
    onReady();
  }, [payment, paymentCreationSuccess, paymentCreationError]);

  return (
    <Card>
      <CustomBookingId customId={transactionCustomId} />
      {summary && (
        <Section title={t("paymentSummary")}>
          <StyledSummaryPaymentDetail {...summary} />
        </Section>
      )}
      <Descriptions bordered title={t("wireTransferPayment")} column={1}>
        <Descriptions.Item label={t("form.bankName")}>
          {paymentMethodData?.bank}
        </Descriptions.Item>
        <Descriptions.Item label={t("form.name")}>
          {paymentMethodData?.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={supplierData?.country === "Chile" ? "RUT" : "CUIT"}
        >
          {paymentMethodData?.id}
        </Descriptions.Item>
        <Descriptions.Item label={t("accountType.type")}>
          {t(`accountType.${paymentMethodData?.accountType}`)}
        </Descriptions.Item>
        <Descriptions.Item label={t("form.accountNumber")}>
          {paymentMethodData?.accountNumber}
        </Descriptions.Item>
        {paymentMethodData?.cbu && (
          <Descriptions.Item label={t("form.cbu")}>
            {paymentMethodData?.cbu}
          </Descriptions.Item>
        )}
        {paymentMethodData?.alias && (
          <Descriptions.Item label={t("form.alias")}>
            {paymentMethodData?.alias}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Divider />
      <div
        style={{
          backgroundColor: baseTheme.background.highlightBackground,
          padding: 10,
          borderRadius: 5,
        }}
      >
        <Title level={4} style={{ marginTop: 0 }}>
          {t("common:important")} <WarningOutlined />
        </Title>
        <Paragraph>{t("wireTransferDisclaimer")}</Paragraph>
        <Paragraph>
          {t("transaction")}: <b>{transactionCustomId}</b>
        </Paragraph>
        <Paragraph strong>{supplierData?.email}</Paragraph>
      </div>
    </Card>
  );
};

WireTransferPaymentMethodScreen.propTypes = {
  onFinish: PropTypes.func,
};

export default WireTransferPaymentMethodScreen;
