import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/Section/Section";
import FeaturesContent from "./components/FeaturesContent/FeaturesContent";
import HeaderSection from "./components/HeaderSection/HeaderSection";
import FeaturedExperiences from "./components/FeaturedExperiences/FeaturedExperiences";
import AboutUsContent from "./components/AboutUsContent/AboutUsContent";
import FaqContent from "./components/FaqContent/FaqContent";
import ContactUsContent from "./components/ContactUsContent/ContactUsContent";
import ContactFormContainer from "./containers/ContactFormContainer";
import {
  ContactUsSection,
  FeatureContainer,
} from "./components/HomePageScreen.styles";
import { shallowEqual, useSelector } from "react-redux";
import { flatten, isEmpty } from "lodash";
import { getImageUrl } from "../../helpers/cloudinary";
import {
  getSupplierData,
  getTypeVisualization,
  getWebsiteContentConfig,
  getWebsiteReviewsConfig,
} from "../../Redux/Selectors/appConfigSelector";
import LayoutWithDataContainer from "../../containers/LayoutWithDataContainer";
import BootstrapIcon from "../../components/BootstrapIcon/BoostrapIcon";
import RichTextRender from "../../components/RichTextRender/RichTextRender";
import useGlobalUtils from "../../hooks/useGlobalUtils";
import { ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../services/mixpanel";
import {
  RENTALS,
  isRental,
  getSpExperiencePromotion,
} from "../../helpers/rentals";
import { getAllExperiences } from "../ExperiencesPage/redux/selectors/ExperiencesSelector";
import { PRIMARY_LINK_TYPES } from "./helpers/constants";
import PaymentIntegrations from "./components/PaymentIntegrations/PaymentIntegrations";
import PaymentIntegrationsContainer from "./containers/PaymentIntegrationsContainer";
import OpeningHours from "./components/OpeningHours/OpeningHours";
import SponsorImages from "./components/SponsorImages/SponsorImages";
import useWindowSize from "../../hooks/useWindowSize";
import { getSlicedExperiences } from "./helpers/helpers";
import HighlightSpacesContent from "./components/HighlightSpacesContent/HighlightSpacesContent";
import MapCroquis from "./components/MapCroquis/MapCroquis";
import YoutubeViewer from "../ExperiencesPage/detail/components/YoutubeViewer/YoutubeViewer";
import Col from "antd/es/grid/col";
import useWebsiteRegion from "../../hooks/useWebsiteCountry";
import ReviewsSection from "./components/ReviewsSection/ReviewsSection";
import BannerRenderContainer from "../../components/BannerComponent/BannerRenderContainer";
import { matchVisualization } from "../../utils/typeVisualization";
import FeaturedProductsContainer from "./components/FeaturedProducts/FeaturedProductsContainer";

const HomePageScreeen = ({ isIframe }) => {
  const { t } = useTranslation(["home"]);
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const { region } = useWebsiteRegion();
  const content = useSelector(getWebsiteContentConfig, shallowEqual);
  const reviewsConfig = useSelector(getWebsiteReviewsConfig);
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const experiences = useSelector(getAllExperiences);
  const displayType = useSelector(getTypeVisualization, shallowEqual);
  const { getSocialMediaLinksData, handleSendWhatsApp } = useGlobalUtils();
  const spExperiencePromotion = getSpExperiencePromotion(
    supplierData?._id,
    region
  );

  const handleSendMessage = (formInstance, values) => {
    const message = values.message;
    trackEvent(clickEvents.SEND_MESSAGE, {
      page: "HomePage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
      message,
    });
    handleSendWhatsApp(message);
    formInstance.resetFields();
  };

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "HomePage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  const handleNavigation = (type) => {
    if (type === PRIMARY_LINK_TYPES.EXPERIENCE) {
      navigate("/experiences" + location.search);
    } else if (type === PRIMARY_LINK_TYPES.SPACE) {
      navigate("/spaces" + location.search);
    } else {
      navigate(matchVisualization(displayType) + location.search);
    }
  };

  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      <HeaderSection
        title={content.headerTitle}
        description={content.headerDescription}
        bgImage={content.headerBackgroundImage}
        socialLinks={getSocialMediaLinksData()}
        onClickButton={handleNavigation}
        experiencesLength={experiences?.length}
      />
      {!isEmpty(content.highlightProducts) &&
        isRental(RENTALS.campingLaIndia, supplierData._id) && (
          <Section id="featured-gear">
            <HighlightSpacesContent productIds={content.highlightProducts} />
          </Section>
        )}

      {!isEmpty(content.highlightProducts) &&
        !isRental(RENTALS.campingLaIndia, supplierData._id) && (
          <Section id="featured-gear" title={t("titles.featuredGear")}>
            <FeaturedProductsContainer
              productIds={content.highlightProducts}
              onClick={handleNavigation}
            />
          </Section>
        )}

      <BannerRenderContainer screen="homePage" />

      {experiences.length > 0 && (
        <Section
          title={t("titles.featuredExperiences")}
          addonAfter={{
            text: t("seeMoreExperiences"),
            navigate: "/experiences",
          }}
        >
          <FeaturedExperiences
            experiences={getSlicedExperiences(experiences, width)}
            promotionExperience={spExperiencePromotion}
            location={location.search}
          />
        </Section>
      )}

      <FeatureContainer>
        <FeaturesContent
          features={[
            {
              icon: <BootstrapIcon iconName="search" />,
              rightArrow: <BootstrapIcon iconName="bi bi-chevron-right" />,
              description: t("howItWorks.findGear"),
              mobileDescription: t("howItWorks.mobile.findGear"),
            },
            {
              icon: <BootstrapIcon iconName="calendar3" />,
              rightArrow: <BootstrapIcon iconName="bi bi-chevron-right" />,
              description: t("howItWorks.selectDates"),
              mobileDescription: t("howItWorks.mobile.selectDates"),
            },
            {
              icon: <BootstrapIcon iconName="credit-card-2-back" />,
              rightArrow: <BootstrapIcon iconName="bi bi-chevron-right" />,
              description: t("howItWorks.selectPaymentMethod"),
              mobileDescription: t("howItWorks.mobile.selectPaymentMethod"),
            },
            {
              icon: <BootstrapIcon iconName="bi bi-emoji-smile" />,
              description: t("howItWorks.enjoyAdventure"),
              mobileDescription: t("howItWorks.mobile.enjoyAdventure"),
            },
          ]}
        />
      </FeatureContainer>

      {isRental(RENTALS.ananda, supplierData?._id) && (
        <Section style={{ justifyContent: "center", display: "flex" }}>
          <Col xs={24} sm={20} lg={16}>
            <YoutubeViewer
              url={"https://www.youtube.com/watch?v=iHZKZNh88wg"}
              width="400px"
            />
          </Col>
        </Section>
      )}

      <Section>
        <PaymentIntegrationsContainer supplierId={supplierData?._id}>
          {({ paymentMethods }) => {
            return (
              !isEmpty(paymentMethods) && (
                <PaymentIntegrations
                  paymentMethods={flatten(paymentMethods)?.map(
                    (elem) => elem.paymentMethod
                  )}
                />
              )
            );
          }}
        </PaymentIntegrationsContainer>
      </Section>

      <Section id="about-us" title={t("titles.aboutUs")}>
        <AboutUsContent
          logo={getImageUrl(content?.aboutUsImage)}
          supplierId={supplierData?._id}
        >
          {content?.aboutUs && (
            <RichTextRender
              style={{ fontSize: 18, lineHeight: 1.5 }}
              content={content?.aboutUs}
            />
          )}
        </AboutUsContent>
      </Section>

      {reviewsConfig?.enabled && (
        <Section>
          <ReviewsSection />
        </Section>
      )}

      {!isEmpty(supplierData?.faqs) && (
        <Section id="faq" title={t("titles.faq")}>
          <FaqContent
            faqs={supplierData.faqs.map(({ title, answer }, key) => ({
              key,
              title,
              content: <RichTextRender content={answer} />,
            }))}
            onClickButton={handleNavigation}
          />
        </Section>
      )}

      {!isRental(RENTALS.campingLaIndia, supplierData._id) &&
        !isRental(RENTALS.spAventuras, supplierData._id) && (
          <Section id="openingHours" title={t("titles.ourOpeningHours")}>
            <OpeningHours
              openingHours={supplierData?.openingHours}
              openingConfig={supplierData?.openingConfig}
            />
          </Section>
        )}
      {isRental(RENTALS.campingLaIndia, supplierData._id) && (
        <Section title={t("titles.howToGetThere")}>
          <MapCroquis src="https://res.cloudinary.com/dja5ahopr/image/upload/v1703202941/Websites/ybdabgpbrp5tqcx9f8pr.jpg" />
        </Section>
      )}
      <ContactUsSection id="contact-us" title={t("titles.contactUs")}>
        <ContactFormContainer>
          {({ formInstance }) => (
            <ContactUsContent
              onSendMessage={(values) =>
                handleSendMessage(formInstance, values)
              }
              formInstance={formInstance}
              phoneNumbers={[supplierData?.phone]}
              map={{
                coordinates: supplierData?.geolocation?.coordinates,
                zoom: 8,
              }}
              address={supplierData?.location}
            />
          )}
        </ContactFormContainer>
      </ContactUsSection>
      {content?.sponsors && (
        <Section title={t("titles.ourSponsors")}>
          <SponsorImages sponsorImages={content.sponsors} />
        </Section>
      )}
      <ScrollRestoration />
    </LayoutWithDataContainer>
  );
};

export default HomePageScreeen;
