export const PERFORM_TRANSACTION_REQUEST = "PERFORM_TRANSACTION_REQUEST";
export const PERFORM_TRANSACTION_SUCCESS = "PERFORM_TRANSACTION_SUCCESS";
export const PERFORM_TRANSACTION_FAILURE = "PERFORM_TRANSACTION_FAILURE";

export const GET_TRANSACTION_BY_ID_REQUEST = "GET_TRANSACTION_BY_ID_REQUEST";
export const GET_TRANSACTION_BY_ID_SUCCESS = "GET_TRANSACTION_BY_ID_SUCCESS";

export const SIMULATE_BOOKING = "SIMULATE_BOOKING";

export const getTransactionByIdRequest = (id, currency) => {
  return {
    type: GET_TRANSACTION_BY_ID_REQUEST,
    payload: {
      id,
      currency,
    },
  };
};

export const getTransactionByIdSuccess = (results) => {
  return {
    type: GET_TRANSACTION_BY_ID_SUCCESS,
    payload: {
      results,
    },
  };
};

export const performTransactionRequest = (
  listing,
  startDate,
  endDate,
  period,
  currency,
  passengers,
  pickAndDrop,
  discountCode,
  referral,
  creationSource,
  housingInfo,
  autoConfirm = false,
  dropoffShop
) => {
  return {
    type: PERFORM_TRANSACTION_REQUEST,
    payload: {
      listing,
      from: startDate,
      to: endDate,
      period,
      currency,
      passengers,
      pickAndDrop,
      discountCode,
      referral,
      creationSource,
      housingInfo,
      autoConfirm,
      dropoffShop,
    },
  };
};

export const performTransactionSuccess = (result) => {
  return {
    type: PERFORM_TRANSACTION_SUCCESS,
    payload: {
      result,
    },
  };
};

export const performTransactionFailure = (errorMessage) => {
  return {
    type: PERFORM_TRANSACTION_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const simulateBooking = (variant, { from, to }) => {
  return {
    type: SIMULATE_BOOKING,
    payload: {
      variant,
      from,
      to,
    },
  };
};
