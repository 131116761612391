import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Card, Descriptions, Divider } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPaymentMethodsFromSupplierRequest,
  transactionPaymentCreateRequest,
} from "../redux/paymentActions";
import {
  getPaymentCreationState,
  getPaymentMethodsData,
} from "../redux/paymentSelectors";
import { getPaymentById } from "../../../Redux/Selectors/paymentSelector";
import Title from "antd/es/typography/Title";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import CustomBookingId from "../../../components/CustomBookingId/CustomBookingId";
import { StyledSummaryPaymentDetail } from "../components/PaymentMethodSelection.styles";
import Section from "../components/Section";
import { WarningOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import baseTheme from "../../../config/baseTheme";
import { PAYMENT_METHOD_LABELS } from "../PaymentMethods";

const WesternUnionPaymentScreen = ({
  transactionId,
  transactionCustomId,
  amount,
  currency,
  onReady,
  summary,
}) => {
  const { t } = useTranslation(["shop", "common"]);
  const dispatch = useDispatch();
  const {
    paymentId,
    loading: paymentCreationLoading,
    error: paymentCreationError,
    success: paymentCreationSuccess,
  } = useSelector(getPaymentCreationState, shallowEqual);
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const payment = useSelector((state) => getPaymentById(state, paymentId));
  const paymentMethodData = useSelector((state) =>
    getPaymentMethodsData(state, PAYMENT_METHOD_LABELS.westernUnion)
  );

  useEffect(() => {
    dispatch(
      transactionPaymentCreateRequest({
        provider: PAYMENT_METHOD_LABELS.westernUnion,
        amount,
        currency,
        transactionId,
      })
    );
    dispatch(getPaymentMethodsFromSupplierRequest(supplierData._id));
  }, []);

  useEffect(() => {
    if (
      !payment ||
      paymentCreationLoading ||
      (!paymentCreationSuccess && !paymentCreationError)
    ) {
      return;
    }
    onReady();
  }, [payment, paymentCreationSuccess, paymentCreationError]);

  return (
    <Card>
      <CustomBookingId customId={transactionCustomId} />
      {summary && (
        <Section title={t("paymentSummary")}>
          <StyledSummaryPaymentDetail {...summary} />
        </Section>
      )}
      <Descriptions bordered title={t("westernUnionPayment")} column={1}>
        <Descriptions.Item label={t("form.name")}>
          {paymentMethodData?.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={supplierData?.country === "Chile" ? "RUT" : "CUIT"}
        >
          {paymentMethodData?.id}
        </Descriptions.Item>
        <Descriptions.Item label={t("form.withdrawal")}>
          {paymentMethodData?.withdrawal}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <div
        style={{
          backgroundColor: baseTheme.background.highlightBackground,
          padding: 10,
          borderRadius: 5,
        }}
      >
        <Title level={4} style={{ marginTop: 0 }}>
          {t("common:important")} <WarningOutlined />
        </Title>
        <Paragraph>{t("westernUnionDisclaimer.title")}</Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_1")}</Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_2")}</Paragraph>
        <Paragraph>
          {t("westernUnionDisclaimer.step_3", {
            name: paymentMethodData?.name,
            withdrawal: paymentMethodData?.withdrawal,
            id: paymentMethodData?.id,
          })}
        </Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_4")}</Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_5")}</Paragraph>
        <Paragraph>
          {t("transaction")}: <b>{transactionCustomId}</b>
        </Paragraph>
        <Paragraph strong>{supplierData?.email}</Paragraph>
      </div>
    </Card>
  );
};

WesternUnionPaymentScreen.propTypes = {
  onFinish: PropTypes.func,
};

export default WesternUnionPaymentScreen;
