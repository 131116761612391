export const getTransactionStatus = (state) => {
  const { data, fetching } = state.pages.shop.transactionStatus;

  return {
    data,
    status: data?.status,
    _id: data?._id,
    fetching,
  };
};
