import {
  countBy,
  groupBy,
  intersection,
  isEmpty,
  concat,
  uniqueId,
} from "lodash";
import { getCurrencyConfig } from "../../../../helpers/tools";
import { getProducts } from "../../../../Redux/Selectors/productSelectors";
import { getBundles } from "../../../../Redux/Selectors/bundlesSelectors";
import {
  getVariantById,
  getVariants,
} from "../../../../Redux/Selectors/variantSelectors";
import {
  productsSchema,
  productSchema,
} from "../../../../Redux/Schemas/productSchema";
import {
  bundlesSchema,
  bundleSchema,
} from "../../../../Redux/Schemas/bundlesSchema";
import { denormalize } from "normalizr";
import { sanitiseVariants } from "../../utils";
import { ITEM_TYPE } from "../../constants";
import { PERIODS } from "../../../../helpers/dates";

export const getShopProducts = (
  state,
  activity,
  searchByName,
  rentalPeriodType,
  byCategory = true,
  filterVisibleOnWeb = true
) => {
  const { results } = state.pages.shop.products;
  const products = getProducts(state, results);
  let sanitizedProducts = products;
  if (filterVisibleOnWeb) {
    sanitizedProducts = products.filter((products) => products.visibleOnWeb);
  }
  const bundles = getBundlesData(state);
  const productsAndBundles = concat(sanitizedProducts, bundles);
  let filteredProducts = productsAndBundles;
  if (rentalPeriodType) {
    filteredProducts = filterByPeriodType(rentalPeriodType, filteredProducts);
  }
  if (activity) {
    filteredProducts = filterByActivity(filteredProducts, activity);
  }

  if (searchByName) {
    filteredProducts = filterBySearchName(filteredProducts, searchByName);
  }
  filteredProducts.forEach((product) => {
    if (!product.category && !isEmpty(product.products)) {
      product.category = { value: "bundles" };
    }
  });
  if (byCategory) {
    filteredProducts = groupBy(filteredProducts, "category.value");
  }
  return filteredProducts;
};

export const getBundlesData = (state) => {
  const { results } = state.pages.shop.bundles;
  return getBundles(state, results);
};

export const getProductsIsFetching = (state) => {
  const { isFetching } = state.pages.shop.products;
  return isFetching;
};

export const getPricingIsFetching = (state) => {
  return state.pages.shop.pricing?.isFetching;
};

export const getItems = (state, arrayOfIds) => {
  const { entities } = state;

  const products = denormalize(arrayOfIds, productsSchema, entities);
  const bundles = denormalize(arrayOfIds, bundlesSchema, entities);

  const items = concat(products, bundles).filter(Boolean);

  return items;
};

export const getItemById = (state, id) => {
  const { entities } = state;
  const product = denormalize(id, productSchema, entities);
  const bundle = denormalize(id, bundleSchema, entities);
  if (product) {
    return product;
  }

  if (bundle) {
    return bundle;
  }

  return null;
};
export const getSummary = (state) => {
  return state.pages.shop.summary;
};

export const getPricingData = (state) => {
  const { pricing } = state.pages.shop.pricing;
  if (pricing) {
    return {
      periodType: pricing.detail[0].unitType,
      length: pricing.detail[0].timeQuantity,
      commission: pricing.host_commission_fee,
      items: pricing.detail,
      payNow: pricing.pay_now,
      subtotal: pricing.subtotal,
      total: pricing.total,
      currency: getCurrencyConfig(pricing.currency),
      discounts: pricing.discounts,
      hostCurrency: getCurrencyConfig(pricing.hostCurrency),
      hostTotal: pricing.hostTotal,
    };
  } else {
    return null;
  }
};

export const getPassengersData = (state) => {
  const { passengers } = state.pages.shop.build;
  return Object.values(passengers);
};
export const getVariantsFromAllProducts = (state, product) => {
  const {
    pages: {
      shop: {
        variants: { products },
      },
    },
  } = state;
  const variantsId = getVariants(state, products[product]);
  const variants = sanitiseVariants(variantsId);
  return variants;
};

export const getVariantsFromProductIsFetching = (state) => {
  const { isFetching } = state.pages.shop.variants;
  return isFetching;
};

export const getPassengersByKey = (state, key) => {
  const { passengers } = state.pages.shop.build;
  return passengers[key];
};

export const getAssigmentByFieldKey = (state, key) => {
  const { assignments } = state.pages.shop.build;
  if (assignments && assignments[key]) {
    return assignments[key];
  }
  return null;
};

export const getAssigmentsData = (state) => {
  const { assignments } = state.pages.shop.build;
  return assignments;
};

export const getAssignmentFailure = (state) => {
  const { assignmentFailure } = state.pages.shop.build;
  return assignmentFailure;
};

export const getAssignmentsLength = (state) => {
  const { assignments } = state.pages.shop.build;
  if (!isEmpty(assignments)) {
    return Object.keys(assignments).length;
  }
  return 0;
};

export const getNormalizePassengers = (state) => {
  const passengersData = getPassengersData(state);
  if (!passengersData || passengersData.length === 0) return null;
  const arrayOfPassengers = passengersData.map((passenger) => {
    const weight = passenger.weight
      ? {
          weight: {
            value: passenger.weight,
            unit: "kgs",
          },
        }
      : {};

    const height = passenger.height
      ? {
          height: {
            value: passenger.height,
            unit: "cms",
          },
        }
      : {};
    const footLength = passenger.footLength
      ? {
          footLength: {
            value: passenger.footLength,
            unit: "cms",
          },
        }
      : {};
    const experience = passenger.experience
      ? {
          experience: { value: passenger.experience },
        }
      : {};
    const items = getAssignedItems(state, passenger.id);
    return {
      personalInfo: {
        name: passenger.name,
        deletable: passenger.deletable ?? true,
        id: uniqueId(Date.now()),
        ...weight,
        ...height,
        ...footLength,
        ...experience,
      },
      items: items ?? [],
    };
  });
  return arrayOfPassengers;
};

export const normalizeItems = (state, item) => {
  const product = getItemById(state, item._id);
  return {
    type: item?.type,
    reference: item?.reference,
    title: product?.title,
    image: product?.images[0],
    fieldKey: item?.fieldKey,
    item: null,
    _id: item._id,
  };
};

export const getAssignedItems = (state, passengerId) => {
  const assignmentsData = getAssigmentsData(state);
  if (typeof passengerId === "number" && !isEmpty(assignmentsData)) {
    const passengersByKey = groupBy(assignmentsData, "passenger");
    if (passengersByKey[passengerId]) {
      return passengersByKey[passengerId].map((item) => {
        const normalizedItem = normalizeItems(state, item);
        return {
          ...normalizedItem,
          variants: !isEmpty(item?.variants)
            ? item.variants.map((variant) => normalizeItems(state, variant))
            : [],
        };
      });
    }
    return null;
  }
  return null;
};

export const checkPassengersRequiredInfo = (state) => {
  const requiredLength = getUsersWithRequiredInfoDone(state);
  const usersWithProductsRequiredInfoLength = getUsersNeedsRequiredInfo(state);

  if (usersWithProductsRequiredInfoLength === requiredLength) {
    return true;
  } else {
    return false;
  }
};

// get how many passengers has their required info done
const getUsersWithRequiredInfoDone = (state) => {
  const {
    build: { passengers },
  } = state.pages.shop;

  const arrayOfPassengers = Object.values(passengers).filter((passenger) => {
    if (passenger.requiredInfo) {
      return passenger;
    }
    return null;
  });
  return arrayOfPassengers.length;
};

//check how many users have at least one ski
const getUsersNeedsRequiredInfo = (state) => {
  const {
    build: { assignments },
  } = state.pages.shop;

  const arrayOfAssigments = Object.values(assignments);

  const filterPassengersWithRequiredInfo = arrayOfAssigments.filter(
    (assign) => {
      const product = getItemById(state, assign.productId);
      const requiresExtraInfo = checkProductRequiresInfo(
        product?.category?.value
      );
      if (requiresExtraInfo) {
        return assign;
      }
      return null;
    }
  );
  const count = countBy(filterPassengersWithRequiredInfo, "passenger");
  return Object.keys(count).length;
};

export const checkProductRequiresInfo = (category) => {
  return (
    category === "ski" || category === "snowboard" || category === "bundles"
  );
};

export const getCheckoutSummary = (state) => {
  const {
    build: { assignments, passengers },
  } = state.pages.shop;

  const arrayOfAssigments = Object.values(assignments);
  const normaliseSummary = arrayOfAssigments.map((item) => {
    const paxData = passengers[item.passenger];
    const assigned = [];

    if (item.type === ITEM_TYPE.BUNDLE) {
      const variants = item.variants.map((variant) => ({
        productId: getItemById(state, variant._id),
        variant: getVariantById(state, variant.reference),
      }));

      assigned.push({
        variants,
        productId: getItemById(state, item._id),
      });
    } else {
      assigned.push({
        variant: getVariantById(state, item.reference),
        productId: getItemById(state, item._id),
      });
    }

    return { paxData, assigned };
  });
  const groupByPassenger = groupBy(normaliseSummary, "paxData.id");
  return Object.values(groupByPassenger);
};

const filterBySearchName = (products, searchByName) => {
  return products.filter((item) =>
    item.title.toLowerCase().includes(searchByName.toLowerCase())
  );
};

const filterByActivity = (products, activity) => {
  if (activity === "all") {
    return products;
  }
  return products.filter((element) => {
    const selectedActivity = [activity.value];
    if (intersection(element.activity, selectedActivity).length > 0) {
      return element;
    }
    return null;
  });
};

/**
 * filtra productos en base a su rentalPeriodType.
 * @param {string} rentalPeriodType - representa si se renta por día u hora.
 * @param {array} products - Es un arreglo con todos los productos y sus caracteristicas.
 */
export const filterByPeriodType = (rentalPeriodType, products) => {
  if (!products || !Array.isArray(products)) {
    return [];
  }
  if (
    !rentalPeriodType &&
    (rentalPeriodType !== PERIODS.day || rentalPeriodType !== PERIODS.hour)
  ) {
    return products;
  }
  const resulFinal = products.filter(
    (product) => product.rentalPeriodType === rentalPeriodType
  );
  return resulFinal;
};

export const getTicketsQuantity = (state) => {
  const { tickets } = state.pages.shop.summary;
  if (!isEmpty(tickets)) {
    return Object.values(tickets).reduce((total, rate) => total + rate, 0);
  }
  return 0;
};

export const getReferralsData = (state) => {
  const { referrals } = state.pages.shop.build;
  return referrals;
};
export const getReferralsIsFetching = (state) => {
  const { referralsFetching } = state.pages.shop.build;
  return referralsFetching;
};

export const getBranchSelected = (state) => {
  const pickUpBranch = state.pages.shop.summary?.pickUpBranch;
  const dropOffBranch = state.pages.shop.summary?.dropOffBranch;
  return { pickUpBranch, dropOffBranch };
};
