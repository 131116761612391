import { listTypes } from "../../utils";
export const GET_PRODUCTS_SHOP_REQUEST = "GET_PRODUCTS_SHOP_REQUEST";
export const GET_PRODUCTS_SHOP_SUCCESS = "GET_PRODUCTS_SHOP_SUCCESS";
export const GET_BUNDLES_SHOP_REQUEST = "GET_BUNDLES_SHOP_REQUEST";
export const GET_BUNDLES_SHOP_SUCCESS = "GET_BUNDLES_SHOP_SUCCESS";
export const GET_ALL_PRODUCTS_SHOP_REQUEST = "GET_ALL_PRODUCTS_SHOP_REQUEST";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const SUMMARY_ADD_ITEM = "SUMMARY_ADD_ITEM";
export const SUMMARY_SUBTRACT_ITEM = "SUMMARY_SUBTRACT_ITEM";
export const SUMMARY_ADD_TICKET = "SUMMARY_ADD_TICKET";
export const SUMMARY_SUBTRACT_TICKET = "SUMMARY_SUBTRACT_TICKET";
export const SET_EXPERIENCE_ID = "SET_EXPERIENCE_ID";
export const GET_PRICING_REQUEST = "GET_PRICING_REQUEST";
export const GET_PRICING_SUCCESS = "GET_PRICING_SUCCESS";
export const BUILD_ADD_PASSENGERS = "BUILD_ADD_PASSENGERS";
export const BUILD_REMOVE_PASSENGERS = "BUILD_REMOVE_PASSENGERS";
export const ASSIGN_VARIANT_TO_PASSENGER = "ASSIGN_VARIANT_TO_PASSENGER";
export const ASSIGN_DEFAULT_VARIANT_TO_PASSENGER_FAILURE =
  "ASSIGN_DEFAULT_VARIANT_TO_PASSENGER_FAILURE";
export const ASSIGN_DEFAULT_VARIANT_TO_PASSENGER =
  "ASSIGN_DEFAULT_VARIANT_TO_PASSENGER";
export const UNASSIGN_VARIANT_TO_PASSENGER = "UNASSIGN_VARIANT_TO_PASSENGER";
export const RESET_ASSIGNMENTS = "RESET_ASSIGNMENTS";
export const ADD_PASSENGER_REQUIRED_INFO = "ADD_PASSENGER_REQUIRED_INFO";
export const GET_VARIANTS_FROM_PRODUCT_REQUEST =
  "GET_VARIANTS_FROM_PRODUCT_REQUEST";
export const GET_VARIANTS_FROM_PRODUCT_SUCCESS =
  "GET_VARIANTS_FROM_PRODUCT_SUCCESS";
export const GET_VARIANTS_FROM_PRODUCT_FAILURE =
  "GET_VARIANTS_FROM_PRODUCT_FAILURE";
export const SET_PRODUCTS_VARIANTS = "SET_PRODUCTS_VARIANTS";
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";
export const SET_DATE = "SET_DATE";
export const SET_BOOKING_PERIOD = "SET_BOOKING_PERIOD";
export const SET_START_TIME = "SET_START_TIME";
export const RESET_SUMMARY = "RESET_SUMMARY";
export const RESET_SUMMARY_PRODUCTS = "RESET_SUMMARY_PRODUCTS";
export const GET_REFERRALS_REQUEST = "GET_REFERRALS_REQUEST";
export const GET_REFERRALS_SUCCESS = "GET_REFERRALS_SUCCESS";
export const SET_PICK_UP_AND_DROP_OFF_BRANCH =
  "SET_PICK_UP_AND_DROP_OFF_BRANCH";

export const checkoutRequest = (userData) => {
  return {
    type: CHECKOUT_REQUEST,
    payload: {
      userData,
    },
  };
};

export const checkoutSuccess = () => {
  return {
    type: CHECKOUT_SUCCESS,
  };
};

export const checkoutFailure = () => {
  return {
    type: CHECKOUT_FAILURE,
  };
};

export const setProductsVariants = (variants, product) => {
  return {
    type: SET_PRODUCTS_VARIANTS,
    payload: {
      variants,
      product,
    },
  };
};
export const getAllProductsShopRequest = (params) => {
  return {
    type: GET_ALL_PRODUCTS_SHOP_REQUEST,
    payload: {
      params,
    },
  };
};
export const getProducsShopSuccess = (results) => {
  return {
    type: GET_PRODUCTS_SHOP_SUCCESS,
    payload: {
      results,
    },
  };
};

export const getBundlesShopSuccess = (results) => {
  return {
    type: GET_BUNDLES_SHOP_SUCCESS,
    payload: {
      results,
    },
  };
};

export const getActivitiesSuccess = (results) => {
  return {
    type: GET_ACTIVITIES_SUCCESS,
    payload: {
      results,
    },
  };
};

export const summaryAddItem = (productId) => {
  return {
    type: SUMMARY_ADD_ITEM,
    payload: {
      productId,
    },
  };
};

export const resetSummaryProducts = () => {
  return {
    type: RESET_SUMMARY_PRODUCTS,
  };
};

export const summarySubstractItem = (productId) => {
  return {
    type: SUMMARY_SUBTRACT_ITEM,
    payload: {
      productId,
    },
  };
};

export const summaryAddTicket = (ticketRate) => {
  return {
    type: SUMMARY_ADD_TICKET,
    payload: {
      ticketRate,
    },
  };
};

export const summarySubtractTicket = (ticketRate) => {
  return {
    type: SUMMARY_SUBTRACT_TICKET,
    payload: {
      ticketRate,
    },
  };
};

export const addExperienceToSummary = (experienceId) => {
  return {
    type: SET_EXPERIENCE_ID,
    payload: {
      experienceId,
    },
  };
};

export const getPricingRequest = (
  from,
  to,
  period,
  items,
  customPricing = {},
  currency,
  allowDiscounts,
  type = listTypes.PRODUCT,
  discountCode
) => {
  return {
    type: GET_PRICING_REQUEST,
    payload: {
      from,
      to,
      period,
      items,
      customPricing,
      currency,
      allowDiscounts,
      type,
      discountCode,
    },
  };
};

export const getPricingSuccess = (results) => {
  return {
    type: GET_PRICING_SUCCESS,
    payload: {
      results,
    },
  };
};

export const assignVariantToPassenger = ({
  passenger,
  variantId = null,
  productId,
  bundleId,
  index,
  type,
  experienceId,
  fieldkey,
  id,
}) => {
  return {
    type: ASSIGN_VARIANT_TO_PASSENGER,
    payload: {
      passenger,
      variantId,
      productId,
      bundleId,
      index,
      type,
      experienceId,
      fieldkey,
      id,
    },
  };
};

export const assignDefaultVariantToPassengerFailure = () => {
  return {
    type: ASSIGN_DEFAULT_VARIANT_TO_PASSENGER_FAILURE,
  };
};

export const assignDefaultVariantToPassenger = ({
  passenger,
  productId,
  bundleId,
  index,
  type,
  experienceId,
  fieldkey,
  id,
}) => {
  return {
    type: ASSIGN_DEFAULT_VARIANT_TO_PASSENGER,
    payload: {
      passenger,
      productId,
      bundleId,
      index,
      type,
      experienceId,
      fieldkey,
      id,
    },
  };
};

export const unassignVariantToPassenger = ({ index, productId }) => {
  return {
    type: UNASSIGN_VARIANT_TO_PASSENGER,
    payload: {
      index,
      productId,
    },
  };
};

export const resetAssignments = () => {
  return {
    type: RESET_ASSIGNMENTS,
  };
};

export const quoteBuildAddPassengers = (passengers) => {
  return {
    type: BUILD_ADD_PASSENGERS,
    payload: {
      passengers,
    },
  };
};

export const quoteBuildRemovePassenger = (id) => {
  return {
    type: BUILD_REMOVE_PASSENGERS,
    payload: {
      id,
    },
  };
};

export const addPassengerRequiredInfo = (data) => {
  return {
    type: ADD_PASSENGER_REQUIRED_INFO,
    payload: {
      data,
    },
  };
};

export const getVariantsFromProductRequest = (productId) => {
  return {
    type: GET_VARIANTS_FROM_PRODUCT_REQUEST,
    payload: {
      productId,
    },
  };
};

export const getVariantsFromProductSuccess = (results) => {
  return {
    type: GET_VARIANTS_FROM_PRODUCT_SUCCESS,
    payload: {
      results,
    },
  };
};

export const getVariantsFromProductFailure = (error) => {
  return {
    type: GET_VARIANTS_FROM_PRODUCT_FAILURE,
    payload: {
      error,
    },
  };
};

export const setDates = (dates) => {
  return {
    type: SET_DATE,
    payload: {
      dates,
    },
  };
};

export const setBookingPeriod = (period) => {
  return {
    type: SET_BOOKING_PERIOD,
    payload: {
      period,
    },
  };
};

export const setStartTime = (id) => {
  return {
    type: SET_START_TIME,
    payload: {
      id,
    },
  };
};

export const resetSummary = () => {
  return {
    type: RESET_SUMMARY,
  };
};

export const getReferralsRequest = () => {
  return {
    type: GET_REFERRALS_REQUEST,
  };
};

export const getReferralsSuccess = (results) => {
  return {
    type: GET_REFERRALS_SUCCESS,
    payload: {
      results,
    },
  };
};

export const setPickUpAndDropOffBranch = (branch, key, branchName) => {
  return {
    type: SET_PICK_UP_AND_DROP_OFF_BRANCH,
    payload: {
      branch,
      key,
      branchName,
    },
  };
};
