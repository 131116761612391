import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import Skeleton from "antd/es/skeleton";
import { ReactComponent as MapMarker } from "../../../components/Icons/mapMarker.svg";
import SkeletonContainer from "../../../components/Skeleton/SkeletonContainer";
import useIsMobile from "../../../hooks/useIsMobile";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../../services/mixpanel";
import LayoutWithDataContainer from "../../../containers/LayoutWithDataContainer";
import SpaceDetailContainer from "./containers/SpaceDetailContainer";
import BannerImage from "../../ExperiencesPage/detail/components/BannerImage/BannerImage";
import {
  Container,
  Content,
  Details,
  SpaceDetail,
  Text,
  Title,
} from "./styles/SpaceDetailScreen.styles";
import SpaceDetails from "./components/SpaceDetails";
import Price from "../../ExperiencesPage/components/Price/Price";
import FloatingFooter from "./components/FloatingFooter/FloatingFooter";
import { RENTALS, isRental } from "../../../helpers/rentals";
import { Button } from "../../../components/Button/Button.styles";
import Paragraph from "antd/es/typography/Paragraph";
import { Helmet } from "react-helmet-async";
import { sanitizeRichText } from "../../../helpers/supplier";
import { getImageUrl } from "../../../helpers/cloudinary";
import useWebsiteDomain from "../../../hooks/useWebsiteDomain";

const SpaceDetailScreen = ({ isIframe }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { domain } = useWebsiteDomain();
  const isMobile = useIsMobile();
  const supplierData = useSelector(getSupplierData, shallowEqual);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "SpaceDetailScreen",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  const handleOnContinue = () => {
    const route = "passengers";
    navigate(`${route}${window.location.search}`);
  };

  const addHelmet = (space) => {
    if (space) {
      return (
        <Helmet prioritizeSeoTags>
          <title>
            {supplierData?.name} - {space?.title} - "Tierra del fuego - Tolhuin"
          </title>
          <link rel="canonical" href={`https://${domain}`} />
          <meta
            name="description"
            content={sanitizeRichText(JSON.parse(space?.description))}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://${domain}/spaces/${space?._id}`}
          />
          <meta
            property="og:title"
            content={`${supplierData?.name} - ${space?.title} - Tierra del fuego - Tolhuin`}
          />
          <meta
            property="og:description"
            content={sanitizeRichText(JSON.parse(space?.description))}
          />
          <meta
            property="og:image"
            content={getImageUrl(space?.images[0], {
              width: 192,
              height: 192,
              format: "png",
              crop: "pad",
            })}
          />
          <link
            rel="icon"
            href={getImageUrl(space?.images[0], {
              width: 32,
              height: 32,
              format: "ico",
              crop: "pad",
            })}
          />
          <link
            rel="apple-touch-icon"
            href={getImageUrl(space?.images[0], {
              width: 192,
              height: 192,
              format: "png",
              crop: "pad",
            })}
          />
        </Helmet>
      );
    }
  };

  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      <SpaceDetailContainer spaceId={id}>
        {({ space, variants }) => {
          if (!space) {
            return (
              <Container>
                {addHelmet(space)}
                <SkeletonContainer>
                  <Skeleton active={true} />
                  <Skeleton active={true} />
                </SkeletonContainer>
              </Container>
            );
          }
          return (
            <SpaceDetail>
              {addHelmet(space)}
              <Container removePadding={isMobile}>
                {!isEmpty(space?.images) && (
                  <BannerImage images={space?.images} alt={space?.title} />
                )}
                <Content>
                  <Details>
                    <div>
                      <Title level={4} ellipsis={{ rows: 2 }}>
                        {space?.title}
                      </Title>
                      <Text>{space?.subtitle}</Text>
                    </div>
                    <Price
                      amount={space?.price}
                      currency={space?.currency}
                      level={4}
                      period={"car"}
                    />
                    <SpaceDetails description={space?.description} />
                    {space?.requiredSpecialPermision && (
                      <SpaceDetails
                        description={space?.specialPermissionDescription}
                      />
                    )}
                    <Paragraph type="secondary" style={{ fontSize: 14 }}>
                      Aprobados por la Provincia de Tierra del Fuego y el
                      Instituto Fueguino de turismo para el uso del fuego en el
                      Área de Acampe "LA INDIA" en Tolhuin, bajo el Nº de
                      habilitación 724/23.
                    </Paragraph>
                    {isRental(RENTALS.campingLaIndia, supplierData._id) && (
                      <a
                        href="https://res.cloudinary.com/dja5ahopr/image/upload/v1703202941/Websites/ybdabgpbrp5tqcx9f8pr.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          icon={<MapMarker className="anticon" />}
                          type="primary"
                        >
                          Cómo llegar: Descargar mapa
                        </Button>
                      </a>
                    )}
                  </Details>
                  <FloatingFooter
                    spaceId={id}
                    variants={variants}
                    onContinue={handleOnContinue}
                  />
                </Content>
              </Container>
            </SpaceDetail>
          );
        }}
      </SpaceDetailContainer>
    </LayoutWithDataContainer>
  );
};

export default SpaceDetailScreen;
